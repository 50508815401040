@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities{
  .animation-delay-2000{
    animation-delay: 2s;
  }
  .animation-delay-4000{
    animation-delay: 4s;
  }
}

@import url('https://fonts.googleapis.com/css2?family=Michroma&display=swap');

@layer base {
  body {
    @apply font-[Michroma];
  }
  li{
    @apply px-4;
    @apply cursor-pointer;
  }
}
